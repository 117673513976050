import React from 'react';

import { isBrowser } from '@agile-actors/client/commons/utils';

const NotFound = () => {
  if (isBrowser()) {
    window.location = '/';
  }

  return <div />;
};

export default NotFound;
